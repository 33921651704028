<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      Note
    </template>
    <template #body>
      <p class="word-wrap-break-word">
        {{note}}
      </p>
    </template>
  </modal-wrapper>
</template>

<script>
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalNotes',
  components: { ModalWrapper },
  props: {
    id: String,
    note: String,
  },
};
</script>

<style scoped>

</style>
